import React from "react";
import { graphql } from "gatsby";
import Seo from "../seo/seo";
import RecentBlogPosts from "./recent-posts";
import Footer from "../footer/footer";
import { Link } from "gatsby";
import ShareImg from "../../images/share.svg";
import DownloadPrompt from "../download-prompt/download-prompt";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import logo from "./../../images/logo_no_padding.webp";

import * as styles from "./blog.module.css";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export default class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const profileImage = getImage(this.props.data.profileImage);
    const title = post.frontmatter.title;
    const desc = post.frontmatter.description;

    const appLinkAndroid = post.frontmatter.linkAndroid;
    const appLinkIos = post.frontmatter.linkIos;

    let nav = typeof navigator !== "undefined" ? navigator : null;

    return (
      <article className="bg-background1 pt-10">
        <section className="container mx-auto text-neutral-100 max-w-5xl">
          <Link to="/">
            <img
              src={logo}
              className="h-12 lg:h-20 ml-auto mr-8 my-8"
              alt="Round Tower Indie App Studio Logo"
            />
          </Link>

          <h1 className="px-5 text-4xl lg:text-6xl py-4 font-main font-bold">
            {title}
          </h1>

          <OutboundLink href="https://twitter.com/kpmmmurphy">
            <section className="container flex flex-row md:w-1/2 py-4 md:py-10">
              <GatsbyImage
                className="w-10 md:w-20 my-auto mx-4 max-w-1-0"
                image={getImage(profileImage)}
              />
              <div className="flex flex-col my-auto">
                <p className="font-main font-semibold">
                  by {post.frontmatter.author}
                </p>
                <p className="font-main font-regular text-xs">
                  Mobile Software Engineer
                </p>
              </div>
            </section>
          </OutboundLink>

          <p className="px-5 text-xxs text-neutral-500 font-main">
            {post.frontmatter.dateDisplay}
          </p>

          <section
            className={styles.postBody}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          {nav !== null && nav.canShare && (
            <button
              className="container w-full p-2 mx-auto hover-animation"
              onClick={() => this.share(title, desc)}>
              <img src={ShareImg} alt="Share" className="w-20 mx-auto" />
            </button>
          )}
        </section>

        <RecentBlogPosts />

        {(appLinkAndroid != null || appLinkIos != null) && (
          <DownloadPrompt linkAndroid={appLinkAndroid} linkIos={appLinkIos} />
        )}

        <Footer />
      </article>
    );
  }

  share = (title, desc) => {
    console.log("Share Clicked");

    try {
      if (navigator.canShare) {
        navigator
          .share({
            title: title,
            text: desc,
            url: window.location.href,
          })
          .then(() => {
            console.log("Shared");
          })
          .catch(console.error);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export const Head = ({ data }) => {
  console.log(data);
  var post = data.markdownRemark;
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description}
      isArticle={true}
      image={post.frontmatter.featuredImage.publicURL}
      date={post.frontmatter.date}
      author={post.frontmatter.author}
      slug={post.fields.slug}
    />
  );
};

export const postQuery = graphql`
  query BlogPostSlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }

    profileImage: file(relativePath: { eq: "profileKevin.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      timeToRead
      excerpt(pruneLength: 280)
      wordCount {
        paragraphs
        sentences
        words
      }
      html
      frontmatter {
        date
        dateDisplay
        title
        author
        tags
        description
        appIcon {
          childImageSharp {
            gatsbyImageData
          }
        }
        linkAndroid
        linkIos
        featuredImage {
          publicURL
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
