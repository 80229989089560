import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { isBrowser, isAndroid, isIOS } from "react-device-detect";

const DownloadPrompt = (props) => {
  return (
    <section className="fixed w-full bottom-0">
      <div className="flex flex-row md:w-3/6 lg:w-1/6 mx-2 md:mx-auto lg:mx-auto px-4 rounded-t-2xl backdrop-blur-lg bg-background1/30">
        {props.linkAndroid && (isAndroid || isBrowser) && (
          <OutboundLink href={props.linkAndroid} className="m-auto p-3">
            <button>
              <img
                className="h-14"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                alt="Download from the Google Play Store"
              />
            </button>
          </OutboundLink>
        )}

        {props.linkIos && (isIOS || isBrowser) && (
          <OutboundLink href={props.linkIos} className="m-auto p-3">
            <button>
              <img
                className="h-10"
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
                alt="Download from the App Store"
              />
            </button>
          </OutboundLink>
        )}
      </div>
    </section>
  );
};

export default DownloadPrompt;
