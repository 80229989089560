// extracted by mini-css-extract-plugin
export var active = "blog-module--active--JpIcJ";
export var authorDetails = "blog-module--authorDetails--d1k2R";
export var authorLink = "blog-module--authorLink--5NjCS";
export var authorWrapper = "blog-module--authorWrapper--ba-Yv";
export var blogHeader = "blog-module--blogHeader--EGu67";
export var btnShare = "blog-module--btnShare--bLyW7";
export var lottie = "blog-module--lottie--prlte";
export var postAuthor = "blog-module--postAuthor--YBvmJ";
export var postAuthorDesc = "blog-module--postAuthorDesc--RgXzw";
export var postAuthorImage = "blog-module--postAuthorImage---LH6M";
export var postAuthorTitle = "blog-module--postAuthorTitle--5HAaf";
export var postBody = "blog-module--postBody--pUNji";
export var postDate = "blog-module--postDate--woi5g";
export var postImage = "blog-module--postImage--xz1vF";
export var postImageWrapper = "blog-module--postImageWrapper--T1jLu";
export var postItemDate = "blog-module--postItemDate--aQXjz";
export var postItemImage = "blog-module--postItemImage--sNKmt";
export var postItemTitle = "blog-module--postItemTitle--c8KZw";
export var postItemWrapper = "blog-module--postItemWrapper--OcMwC";
export var postTags = "blog-module--postTags--EioUt";
export var postTitle = "blog-module--postTitle--zAvAX";
export var postWrapper = "blog-module--postWrapper--tgCPC";
export var postsWrapper = "blog-module--postsWrapper--N76sS";
export var recentDate = "blog-module--recentDate--UJC7w";
export var recentImage = "blog-module--recentImage--Wv6XG";
export var recentPostWrapper = "blog-module--recentPostWrapper--kPZes";
export var recentTitle = "blog-module--recentTitle--Y923N";
export var recentsWrapper = "blog-module--recentsWrapper--fUpPB";
export var wrapper = "blog-module--wrapper--LG92A";