import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function PostThumbnail(props) {
  return (
    <Link to={props.slug}>
      <article
        key={props.slug}
        className="container p-4 mb-4 bg-background1 rounded-lg">
        <GatsbyImage
          className="rounded-lg w-full"
          image={getImage(props.image)}
          alt={props.title}
        />
        <h3 className="p-6 font-main font-bold text-xl text-left text-neutral-300">
          {props.title}
        </h3>
        <h5 className="px-6 font-main text-xs text-white/50">{props.date}</h5>
      </article>
    </Link>
  );
}
