import React from "react";
import { StaticQuery, graphql } from "gatsby";

import PostThumbnail from "./post-thumbnail";

export default function RecentBlog() {
  return (
    <StaticQuery
      query={postQuery}
      render={(data) => (
        <section className="bg-background3 mx-auto my-10 rounded-3xl md:w-2/3">
          <h3 className="font-main font-semibold text-4xl text-center text-white py-10">
            Recent Posts
          </h3>
          <section
            className="container md:grid grid-cols-2 gap-10 p-4 md:w-4/5 mx-auto"
            id={"blog-posts"}>
            {data.allMarkdownRemark.edges.map(({ node }) => {
              return (
                <PostThumbnail
                  slug={node.fields.slug}
                  image={node.frontmatter.featuredImage}
                  title={node.frontmatter.title}
                  date={node.frontmatter.dateDisplay}
                />
              );
            })}
          </section>
        </section>
      )}
    />
  );
}

export const postQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          timeToRead
          excerpt(pruneLength: 280)
          wordCount {
            paragraphs
            sentences
            words
          }
          fields {
            slug
          }
          html
          frontmatter {
            dateDisplay
            title
            tags
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
